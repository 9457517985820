a {
    color: #676767 !important;
    text-decoration: none;
}
a:hover,
a:focus{
    color: #282828 !important;
}
.right{float: right;}
.left{float: left;}


select{
	height: 2.4375rem;
    margin: 0 !important;
    padding: 0.5rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid #cacaca;
    border-radius: 0;
    background-color: #fefefe;
    font-family: inherit;
    font-size: 1rem;
    line-height: normal;
    color: #999999;
    background-image: url('../images/select-icon.png') !important;
    -webkit-background-origin: content-box;
    background-origin: content-box;
    background-position: right center !important;
    background-repeat: no-repeat !important;
    padding-right: 1.5rem !important;
    -webkit-transition: border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s;
    transition: border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s;
    text-transform: none !important;
}
.single-query select{height: 50px;}

textarea, input[type='text'], input[type='email'] {
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    outline: none;
    border: 1px solid #ebebeb;
}

textarea::-webkit-input-placeholder {color: #999999;}
textarea:-moz-placeholder {color: #999999;}
textarea::-moz-placeholder {color: #999999;}
textarea:-ms-input-placeholder {color: #999999;}

.row-eq-height {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.owl-carousel .owl-item > img {
    display: block;
    width: 100%;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    text-transform: uppercase;
    position: relative;
    color: #90908f;
    font-family: "Roboto", Arial, Helvetica, sans-serif;
    font-weight: 700;
}

h1, .h1{ font-size: 2.77em; }
h2, .h2{ font-size: 2.31em; }
h3, .h3{ font-size: 1.8em; font-weight: bold;}
h5, h6 { margin: 0px 0px 5px; font-family: "Roboto", sans-serif; color: #000; position: relative;}

input[type=text].error, 
input[type=email].error,
textarea.error{ color: #a94442; background-color: #f2dede; }
select.error{ color: #a94442 !important; background-color: #f2dede !important; }

.border-right {
    border-right: solid 1px rgba(0,0,0,.4);
}
.horz_divider2 { border-bottom: solid 2px rgba(0,0,0,.7);}
.row-eq-height {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.modal-title{font-size: 30px; color: #8e429a;}

.page_content ol{
    display: block;
    list-style-type: decimal;
    -webkit-margin-before: 1em; 
    -webkit-margin-after: 1em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
    -webkit-padding-start: 40px;
}
.page_content ul{
    display: block;
    list-style-type: disc;
    -webkit-margin-before: 1em;
    -webkit-margin-after: 1em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
    -webkit-padding-start: 40px;
}
.page_content ul li,
.page_content ol li{
    font-size: 15px;
    color: #000;
}
header{background-color: #fff;}

.modal-header{position: relative;}
.modal-title{float: left;}

.white_header.navbar{padding-top: 26px;}

.topbar{ color: #000; font-size: 12px; line-height: 4; }
.topbar h6{ color: #000; font-size: 12px; line-height: 4; margin: 0; }

.top-icon-circle, .agent-icon-circle, .top-bar-text a, .top-bar-text a:hover, .label-icon-circle {color: #a5a9ab;}
.top-icon-circle, .dropdown-menu, .agent-icon-circle:hover, .label-icon-circle, .labelled-input-short label:hover .label-icon-circle {
    border: 1px solid #282828;
}
.top-icon-circle {
    display: block;
    border-radius: 50% 50%;
    width: 24px;
    height: 24px;
    line-height: 23px;
    text-align: center;
    margin-top: 8px;
    margin-right: 5px;
    float: left;
    font-size: 11px;
    -webkit-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
}

.top-icon-circle:hover, .agent-icon-circle:hover, .labelled-input-short label:hover .label-icon-circle {
    background: #8d449a;
    color: #fff;
}

.totalListShow{line-height: 50px;}

.social_share_top{list-style: none;,margin: 0;padding: 0;float: right;}
.social_share_top li{height: 39px;list-style: none; display: inline-block; float: left; padding: 0 9px 0 9px;vertical-align: middle; position: relative; }
.social_share_top li a{color: #282828 !important;display: block; outline: none; text-align: center; margin-right: 0; }
.social_share_top li span{display: none;}
.social_share_top li.social_facebook:hover a{color: #fff !important;}
.social_share_top li.social_google:hover a{color: #fff !important;}
.social_share_top li.social_linkedin:hover a{color: #fff !important;}
.social_share_top li.social_twitter:hover a{color: #fff !important;}
.social_share_top li.social_pinterest:hover a{color: #fff !important;}
.social_share_top li.social_instagram:hover a{color: #fff !important;}
.social_share_top li.social_youtube:hover a{color: #fff !important;}


.social_share_footer{list-style: none;,margin: 10px 0;padding: 0;}
.social_share_footer li{list-style: none; display: block; padding: 0 5px;vertical-align: middle; position: relative; }
.social_share_footer li a{color: #282828 !important;display: inline-block; outline: none; margin-right: 0;padding: 5px 0;}
.social_share_footer li a i{position: absolute;left: 0;top: 5px;font-size: 16px;}
.social_share_footer li a span{display: block;padding-left: 20px;text-transform: uppercase;}

.white_header .navbar{
    -webkit-box-shadow: 0px 5px 5px -5px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 5px 5px -5px rgba(0,0,0,0.75);
    box-shadow: 0px 5px 5px -5px rgba(0,0,0,0.75);
}


#parallax_three {
    background: url('../images/working-employee.jpg') top center no-repeat fixed;
}


#layouts .news_media{background-repeat: no-repeat;background-size: 100%;background-position: center center;position: relative;border: solid 1px #aaa;}
#layouts .news_media:after{content: '';background-color: rgba(140, 60, 153, 0.5);position: absolute;left: 0;top: 0;bottom: 0;right: 0;z-index: 0;}
#layouts .news_media:hover{
    background-size: 120%;
    -webkit-transition: background-size 100ms linear;
    -ms-transition: background-size 100ms linear;
    transition: background-size 100ms linear;
}

#layouts .news_media .media-left{display: none;}
#layouts .news_media .media-left > a{
    width: 140px;
    height: 130px;
    display: none;
}
#layouts .news_media .media-body{margin-top: 0;position: relative;z-index: 1;padding: 20px;}
#layouts .news_media .media-body p{color: #fff;}
#layouts .news_media .media-body h3{margin-top: 0;color: #000;font-family: "Oswald", sans-serif;}
#layouts .news_media .media-body h3 a{color: #fff !important;}
#layouts .news_media .media-body a.btn-more{color: #fff;background-color: #50006c;margin: 0 -20px;padding: 0 40px;display: block !important;}
#layouts .news_media .media-body a.btn-more span{color: #fff !important;margin: 5px 0;}

nav #navbar-menu{padding: 0;}
.navbar-brand{padding: 13px 0;}
nav.navbar.bootsnav ul.nav>li{margin-right: 5px;padding: 35px 0;}
nav.navbar.bootsnav ul.nav>li>a{
    outline: none;text-transform: uppercase;
    color: #282828 !important;
    line-height: 20px;
    font-size: 14px;
    font-weight: 400;
    padding: 5px 10px;
}
nav.navbar.bootsnav ul.nav>li> a:hover{ background-color: #8d449a !important;color: #fff !important; }
nav.navbar.bootsnav li.dropdown ul.dropdown-menu{ background-color: #fff; }
nav.navbar.bootsnav li.dropdown ul.dropdown-menu>li{padding: 0 10px;}
nav.navbar.bootsnav li.dropdown ul.dropdown-menu>li:last-child a{border: none;}
nav.navbar.bootsnav li.dropdown ul.dropdown-menu>li>a{
    border-bottom: solid 1px #90908f;text-transform: uppercase; color: #282828 !important;padding: 5px 0;
}
nav.navbar.bootsnav li.dropdown ul.dropdown-menu>li:focus > a, 
nav.navbar.bootsnav li.dropdown ul.dropdown-menu>li:hover > a{
    color: #8d449a !important;background-color: transparent;
}


a.back-to{background: #8d449a;;color: #fff !important;}
a.back-to:hover{background-color: #fff; color: #8d449a !important;}

.callus .form-control, .callus .intro .zelect, .callus .keyword-input, .callus input[type=text], .callus select{ }

#top-slider{position: relative;}
.slidebottom{position: relative;}
.main #top-slider{max-height: 160px;}
.main .slidebottom{margin-bottom: 20px; }
.main .top-slider-item{min-height: 160px;}

.service-box{padding-bottom: 30px;}

.col-lg-2b{width: 20%;}



#featured-slider{position: relative; max-width: 1600px; margin: 0 auto!important; width: 100%; overflow: hidden; }
#featured-slider .item{background-repeat: no-repeat;background-size: cover;background-position: center center;min-height: 500px;}
#featured-slider .item .container{min-height: 500px;position: relative;}
#featured-slider .item a{
    display: block;color: #fff !important;font-family: 'Oswald';font-weight: bold; position: relative;margin-bottom: 50px;
}
#featured-slider .item a.slider_text{
    position: absolute;
    bottom: 10px;
    margin-left: 0;
    float: left;
    clear: both;
    min-width: 0;
    min-height: 0;
    background: transparent;
    padding: 0;
    max-width: 400px;
    z-index: 10;
    left: 30px;
}
#featured-slider .item a .property_info_header{
    width: 100%;
    background: #bcd90b;
    clear: both;
    text-transform: uppercase;
    position: relative;
    -moz-transition: .2s all;
    -webkit-transition: .2s all;
    -o-transition: .2s all;
    -ms-transition: .2s all;
    transition: .2s all;
}
#featured-slider .item a .property_info_header,
#featured-slider .item a .property_rent_sale,
#featured-slider .item a .property_price{padding: 10px 15px;font-size: 24px;font-weight: normal !important;}

#featured-slider .item a .property_rent_sale{background-color: #813790;}
#featured-slider .item a .property_price{background-color: #662d91;}
#featured-slider .item a .row{padding-left: -2px;padding-right: -2px;}
#featured-slider .item a .row .col-lg-4,
#featured-slider .item a .row .col-lg-8,
#featured-slider .item a .row .col-lg-12{padding-left: 2px;padding-right: 2px;}

#featured-slider .owl-nav{
    margin: 0 auto;
    max-width: 1170px;
    width: 100%;
    position: absolute;
    bottom: 18%;
    left: 0;right: 0;
    text-align: right;
}
#featured-slider .owl-prev,
#featured-slider .owl-next{
    text-align: right;
    background-color: #bcd90b;
    color: #fff;
    font-size: 20px;
    z-index: 1;
    margin: 0 2px 0 0;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    display: inline-block;
    position: relative;
}
#featured-slider .owl-prev{top: 50%;left: 0%;}
#featured-slider .owl-next{top: 50%;right: 0%;}

.subtitle-margin{
    letter-spacing: 2.5px;
    margin-bottom: 2px;
    text-transform: uppercase;
    font-size: 0.4em;
    line-height: 1em;
}

h2,.h2{padding: 5px 0 15px 0;color: #90908f;}
h2:after, 
h2:before {
    content: "";
    left: 0;
    position: absolute;
    top: 100%;
}

h2:before {
    border-bottom: 1px solid #EEE;
    height: 0;
    width: 100%;
}
h2:after {
    background: #8d449a;
    height: 3px;
    margin-top: -1px;
    width: 50px;
}
.blue-text{color: #1174ed;}
.property-query-area{width: 100%;padding: 20px 0;}
.property-query-area form{padding: 30px 30px 15px 30px;clear: both;background-color: #F8F8F0;}
.main .property-query-area form{padding: 10px !important;background-color: transparent !important;}

.property-query-area input[type=button], 
.property-query-area input[type=reset], 
.property-query-area input[type=submit],
input[type=submit],
#faq_search input[type=button]{
    height: 50px;
    line-height: 50px;
    padding: 0 10px !important;
    display: inline-block;
    text-transform: uppercase;
    font-size: 1.08em;
    letter-spacing: 1px;
    position: relative;
    overflow: hidden;
    -webkit-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
    white-space: nowrap;
    font-weight: 100;
    background: #8d449a;
    border: none;
}

.property-query-area input[type=button]:hover, 
.property-query-area input[type=reset]:hover, 
.property-query-area input[type=submit]:hover,
#faq_search input[type=button]:hover,
input[type=submit]:hover{
    background: #fff;
    color: #8d449a !important;
}


.searchBox{background-color: none;}
#property_type_tab li a{text-transform: uppercase;font-weight: 700;color: #90908f !important;background-color: #fbfbf8;}
#property_type_tab li.active a{background-color: #F8F8F0;}

.services-we-offer{padding: 30px 0;background-color: #fff;}
.row.services-we-offer-part .services-we-offer-background {
    background-color: #31a2e1;
    border-radius: 50%;
    height: 90px;
    padding: 15px;
    text-align: center;
    vertical-align: middle;
    width: 90px;
    margin: 0 auto 70px auto;
    position: relative;
}
.row.services-we-offer-part .services-we-offer-background i{font-size: 40px;color: #fff;line-height: 55px;}
.row.services-we-offer-part .services-we-offer-background:after {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    border-color: #31a2e1 transparent transparent TRANSPARENT;
    border-image: none;
    border-style: solid;
    border-width: 48px 38px;
    bottom: -75px;
    content: " ";
    height: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    position: absolute;
    width: 0;
    -moz-transition: .2s all;
    -webkit-transition: .2s all;
    -o-transition: .2s all;
    -ms-transition: .2s all;
    transition: .2s all;
}
.service-box:hover .services-we-offer-background:after {
    border-width: 48px 28px;
     bottom: -85px;  
}
.services-we-offer .services-we-offer-part img {
    margin-bottom: 25px;
}
.services-we-offer h4 {
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 25px;
    text-align: center;
    color: #000;
}
.services-we-offer h4 a{color: #000 !important;}
.services-we-offer h4:hover a{color: #31a2e1 !important;}
.row.services-we-offer-part .services-we-offer-background svg {
    fill: #ffffff;
    width: 40px;
}

#main_search.callus .col-md-12, 
#main_search.callus .col-md-2, 
#main_search.callus .col-md-3, 
#main_search.callus .col-md-4, 
#main_search.callus .col-md-8, 
#main_search.callus .col-sm-12, 
#main_search.callus .col-sm-6 {
    padding-left: 15px;
    padding-right: 15px;
}

#main_search.callus .row{ margin-left: -15px; margin-right: -15px; }
.callus .row.tips_box{ margin-left: -15px; margin-right: -15px; }

.padding30{padding: 30px 0;}

#four-item .owl-prev{right: 44px;}
#four-item .owl-prev, 
#four-item .owl-next{
    white-space: nowrap;
    font-weight: 100;
    background: #8d449a;
    color: #fff;
    font-size: 18px;
    border: solid 1px #8d449a;
    border-radius: 0 !important;
    margin-bottom: 5px;
}
#four-item .owl-prev:hover, 
#four-item .owl-next:hover{
    background: #fff;
    color: #8d449a;
}

#usefulLinks{}
#usefulLinks .item .image{width: 106px;margin: 0 auto;padding: 0 15px;position: relative;}
#usefulLinks .item .image > a{vertical-align: middle;display: block;height: 100%;line-height: 70px;}
#usefulLinks .item .image img{margin: 0 auto;}

.contact-page-1{position: relative;}
.contact1-cont {
    bottom: 45px;
    width: 100%;
    position: absolute;
    z-index: 30;
}
.contact1 {
    margin: 0;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.35);
    padding: 60px 15px 60px 15px;
    background: #f8f8f8;
}

.search-propertie-filters h6{margin-bottom: 10px;font-weight: 600;}
.search-form-group.white label > span{font-weight: normal; color: #000;}
.search-form-group input[type=checkbox]{ display: inline-block; margin: 3px 5px 0 0; float: left;}

.intro.no-input .dropdown > .zearch-container{display: none;}

.pagination ul li a{background: #1F3F81;color: #fff;border-color: #1F3F81;}
.pagination ul li a:hover, 
.pagination ul li.selected a{background: #048EBF;color: #fff;border-color: #048EBF;}


#teaser{
    background: url('../images/services_teaser.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100%;
    color: #fff;
    padding-bottom: 0;
}

.teaser_container h1{color: #fff;}
.teaser_container p{color: #fff;}
.teaser_container .article{}
.teaser_container .article h3{font-size: 30px;margin-top: 0;color: #fff;}
.teaser_container .article img{width: 100%;margin-bottom: 50px;}
.teaser_container .article .btn-grey{display: block;text-align: center;margin-bottom: 30px;}
.teaser_container .article p{min-height: 70px;text-align: justify;}

/*---- Property Details  ---*/
#image_div{position: relative;}
#image_div .rent_sale{z-index: 1;position: absolute;top: 10px;right: 10px;background: #1f3f81;color: #fff;padding: 5px 8px;text-transform: uppercase;}
#image_div .property_hover{z-index: 1;line-height: 24px;position: absolute;bottom: 5px;left: 5px;right: 5px;background-color: #1f3f81;color: #fff;padding: 10px;}
#image_div .property_hover .mainPrice{font-size: 24px;line-height: 24px;font-weight: 100;float: left;margin-right: 10px;}
#image_div .property_hover .allPrices .small_price{margin-right: 10px;}

.property-details .propertyStatusBanner{background-color: #EA1D22;border-radius: 3px; color: #fff; padding: 2px 8px;float: right;}

.agent_box{background-color: #e5e5e5;border: 1px solid #d6d6e2;border-bottom: 1px solid #c6c6d7;margin-bottom: 10px;position: relative; margin-top: 85px;}
.agent_box.agent .agent_profile_picture{
	width: 90px;height: 90px;left: 0;right: 0;top: -45px;	
	margin: 0 auto;border: 1px solid #fff;-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	background-color: #fff;position: absolute;background-repeat: no-repeat;background-size: cover;background-position: center center;
}
.agent_box.company .agent_profile_picture{
	width: 100%;height: 90px;
	background-repeat: no-repeat;background-size: cover;background-position: center center;
	background-size: 90%;background-color: #fff;left: 0;right: 0;border-bottom: 1px solid #d6d6e2;
}

.agent_box.company .content{padding-top: 0 !important;}
.agent_box .content{text-align: center;margin-top: 20px;padding-top: 30px;padding-bottom: 10px;}
.agent_box.company .content{margin-top: 10px !important;}
.agent_box .content .agentName{color: #000;font-size: 20px;line-height: 22px;font-weight: bold;}
.agent_box .content .agentTelephone{color: #000;font-size: 13px;}
.agent_box .content .agentTelephone i{font-size: 18px;}
.agent_box .content .agentEmail{color: #000;}

.property_courtesy{font-size: 10px;}
.property_courtesy .courtesy{opacity: 1;}
.property_courtesy abbr{opacity: 0.8;}

.price_comparison{margin-top: 20px;padding-top: 20px;border-top: 2px solid #31495d;}
.price_comparison .average_content{margin-bottom: 20px;}
.price_comparison .average_content .text{color: #313131;font-weight: 300;margin-bottom: 8px;}
.price_comparison .average_content .average_stat{list-style: none;margin: 0;padding: 0;}
.price_comparison .average_content .average_stat li{border-top: 1px dotted rgba(0,0,0,0.1);clear: both;}
.price_comparison .average_content .average_stat li a{display: block;padding: 0 0 0 0;color: #333;height: 30px;line-height: 30px;}
.price_comparison .average_content .average_stat li a .count{float: right;}
.price_comparison .average_content .average_stat li a .count:hover{color: #00aef0;}
.price_comparison .average_content .average_stat li a .text{float: left;min-width: 100px;}
.price_comparison .average_content .average_stat li a .range{font-weight: 600;}


#two-col-slider{margin: 0 -0.5px; }
#two-col-slider .item{padding: 0 0.5px;margin-bottom: 10px;}

.copyright_text{text-align: right;}
.copyright_text_left{text-align: left;}

.table-row{display: table;width: 100%}
.table-row .item{display: table-cell;}

.text-slider-container{
    max-width: 60%;
    margin: 0 auto 30px;
    background-color: rgba(0,0,0,0);
    color: #000;
    padding: 30px;
}



#faq_search{margin-bottom: 30px;}
#faq_search input[type='text'],
#faq_search select{width: 100%;}
#faq_search select{}
#faq_search input[type='button']{
    width: 100%;text-transform: uppercase;padding: 0 10px !important;
    height: 34px;line-height: 34px;
}
.faq_list{margin: 20px 0 !important;}
.faq_list li {}
.faq_list li h5{
    border-bottom: 1px dotted rgba(0,0,0,0.5);
    padding: 10px 0;
    font-size: 30px;
}
.faq_list li .question,
.faq_list li .question p{font-size: 110%;font-weight: bold;color: #1f1d22;}
.faq_list li .question{padding-left: 20px;position: relative;}
.faq_list li .question:before{position: absolute;left: 0;content:"Q: ";top: 1px;}
.faq_list li .answer{padding-left: 20px;font-size: 90%;color: #676767;}
.faq_list li.backtoTop{text-align: right;}
.faq_list li.backtoTop a{background-color: #d00;color: #fff !important;padding: 3px 5px; opacity: 0.8;}
.faq_list li.backtoTop a:hover{background-color: #ffce00;opacity: 1;}
.faq_list li.backtoTop a span{font-size: 10px; text-transform: uppercase;}
.faq_list li.backtoTop a i{font-size: 8px;}



.agent_wrap .agent_contact .agent_profile p{}
.agent_wrap .img-info a{font-size: 12px;color: #282828;}
.agent_wrap .img-info a i{color: #8d449a;}
.agent_wrap .img-info a:hover{color: #8d449a;}



.tips_list li h3{font-size: 16px;}
.tips_list li a:hover{ color: #8d449a !important;}
.tips_list li .backtoTop{text-align: right;}


.search-propertie-filters .container-2{padding: 20px;}
.search-propertie-filters .container-2 .search-form-group label{position: relative;padding-left: 15px;}
.search-propertie-filters .container-2 .search-form-group label input{position: absolute;left: 0;}


#list_div .cool_form .col-lg-3 label{line-height: 50px;}

/**------------------MORTGAGE CALCULATOR--------------------**/
.nmsCalculator{color: #3e3e3e; padding: 10px; -webkit-border-radius: 3px; -moz-border-radius: 3px; border-radius: 3px; overflow: hidden;}
.nmsCalculator .smallLabel{font-size: 12px;}
.nmsCalculator .mortgagePropertyPrice{font-size: 16px;color: #000;}
.nmsCalculator .allPrices{font-size: 10px;line-height: 11px;margin-top: 3px; }

.nmsCalculator .advertBlock{background-color: #000;height: 315px;font-size: 25px;text-align: center;font-weight: 300;padding: 120px 10px;}
.nmsCalculator .advertBlock .noAdHolder{line-height: 16px;display: block;position: relative;width: 100%;color: #8A0027;}
.nmsCalculator .advertBlock .noAdHolder img{margin-bottom: 20px;}
.nmsCalculator input{margin-bottom: 0 !important;}
.nmsCalculator .green_label{}
.nmsCalculator .green_label label{font-size: 12px;}
.nmsCalculator .green_label label em{font-size: 11px;}
.nmsCalculator .grey_block{min-height: 37px; line-height: 30px;}
.nmsCalculator input[type='text'],
.nmsCalculator input[type='email']{
    -webkit-border-radius: 3px;
-moz-border-radius: 3px;
border-radius: 3px;
border: solid 1px rgba(0, 0, 0, 0.5);
}
.nmsCalculator textarea{
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    border: solid 1px rgba(0, 0, 0, 0.5);
}
.mortgageCalculator{ 
    font-size: 16px;color: #282828;
    background-color: #fff0c3; 
    transition: all 0.5s ease !important;
    padding: 15px !important;margin: 0 !important;
}
#mortgageFormBlock h5{font-size: 13px; text-transform: uppercase;margin-top: 5px;font-family: Arial;color: #000;margin-bottom: 0;}
#mortgageCalculatorBootstrap .modal-lg{max-width: 750px;}

.mortgageCalculator .mortgagePropertyPrice {font-size: 16px; color: #000;line-height: 14px;}
.mortgageCalculator .allPrices{margin-top: 5px;}
.mortgageCalculator .allPrices .small_price { color: #282828; font-size: 11px; display: inline-block;float: left;line-height: 14px;}
.mortgageCalculator p{font-size: 11px; margin-bottom: 5px;}

.mortgageCalculator .btn{
    background: #31a2e1!important; color: #fff!important;display: inline-block; height: 35px; line-height: 35px;
    padding: 0 10px !important;margin-left: 10px;font-weight: 700;width: auto;
}
.mortgageCalculator .btn:hover{
        background: #ff551a!important;
    color: #fff!important;
}
.mortTable{font-size: 12px;padding: 0 15px;}
.mortTable .email_notice{font-size: 10px;text-align: left;display: block;}
.mortTable .tableHeader .headert{
    text-align: left;font-weight: bold;font-family: Arial;font-size: 12px;padding: 10px 0 !important;
}


.mortTable .tableContent label{width: 100%;border-bottom: dotted 1px rgba(0, 0, 0, 0.4);width: 100%;padding: 0;display: block;clear: both;}
.mortTable .tableContent label:hover{color: #333490;}
.mortTable .tableContent label:last-child{border: none !important;}

.mortTable .tableContent .item{word-wrap: break-word; padding-left: 0 !important; text-align: left;font-size: 11px;padding: 7px 0 !important;
}
.mortTable .tableContent .item b{font-weight: bold;font-family: Arial;font-size: 12px;}

#mortgageFormBlock.callus .col-md-12, 
#mortgageFormBlock.callus .col-md-2, 
#mortgageFormBlock.callus .col-md-3, 
#mortgageFormBlock.callus .col-md-4, 
#mortgageFormBlock.callus .col-md-8, 
#mortgageFormBlock.callus .col-sm-12, 
#mortgageFormBlock.callus .col-sm-6 {
    padding-left: 1em;
    padding-right: 1em;
}

#mortgageFormBlock label{ color: #000; font-size: 12px !important; }

#mortgageFormBlock input[type='text'],
#mortgageFormBlock input[type='email']{    
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    border: 1px solid rgba(0,0,0,.5) !important;
    height: 2.3125rem;
    margin: 0 0 1rem;
    font-size: 12px;color: #000;
}
#mortgageFormBlock textarea{width: 100%;}

.mortgageCalculator input[type='text'],
.mortgageCalculator input[type='email']{    
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    border: 1px solid rgba(0,0,0,.5) !important;
    height: 2.3125rem;
    margin: 0 0 1rem;
    font-size: 12px;color: #000;
    padding: 3px;
}
.disclaimer{padding: 10px 0;color: rgba(0, 0, 0, 0.5);text-align: left;font-size: 9px;line-height: 12px;font-weight: bold;font-family: Arial;} 
.disclaimer p{text-align: justify;color: rgba(0, 0, 0, 0.5);font-size: 9px;line-height: 11px;font-weight: bold;font-family: Arial;}
/**------------------END MORTGAGE CALCULATOR--------------------**/


.multiple-address{position: relative;margin-bottom: 18px;color: #282828;}
.multiple-address i{position: absolute;left: 0;top: 0;color: #282828;width: 8px;}
.multiple-address .content{padding-left: 26px; font-size: 12px;}

footer .upper{background-color: #bcd90b;padding: 40px 0;}
footer .lower{background: #fff;min-height: 70px;}
footer .line-right{border-right: solid 1px #282828;}

footer h3{
    font-size: 18px !important;
    color: #282828 !important;
    margin: 0 0 10px 0;
    font-weight: normal;
}
footer p{color: #898989;font-size: 12px;margin-bottom: 18px;}
footer .list-title:before{border-bottom: 1px solid #000;}
footer .multiple-address{
    margin-bottom: 0;
}

.social_block{padding: 0 70px;}

.affiliates{list-style: none;margin: 0;padding: 0 30px;}
.affiliates li{position: relative;padding: 5px 0;}
.affiliates li a{display: inline-block;color: #282828 !important;font-size: 12px;}
.affiliates li a > img{position: absolute;left: 0;top: 0;}
.affiliates li a > span{display: inline-block;padding-left: 50px;min-height: 57px;}


footer.inner{
        min-height: 60px;
    text-align: center;
    background: #435061;
    font-size: 14px !important;
    font-family: 'Lato', sans-serif !important;
    line-height: 4;
    padding: 25px 10px;
}
footer.inner p{margin-top: 10px;}

.multiple-agent-form select{
    width: 100%;
    background-color: #0e90d9;
    padding: 5px 20px;
    font-size: 14px;
    font-family: 'Lato', sans-serif;
    color: #b1e2ff;
    border: #0e90d9 !important;
    margin-bottom: 10px !important;
    resize: none;
    height: 40px;
    line-height: normal !important;   
}
.multiple-agent-form input::-webkit-input-placeholder, .multiple-agent-form textarea::-webkit-input-placeholder {
    color: #5d5d5d;
}
.multiple-agent-form input:-moz-placeholder, .multiple-agent-form textarea:-moz-placeholder {
    color: #5d5d5d;
}
.multiple-agent-form input::-moz-placeholder, .multiple-agent-form textarea::-moz-placeholder {
    color: #5d5d5d;
}
.multiple-agent-form input:-ms-input-placeholder, .multiple-agent-form textarea:-ms-input-placeholder {
    color: #5d5d5d;
}

input.error::-webkit-input-placeholder { color: #C7254E;}
input.error::-moz-placeholder { color: #C7254E; } /* firefox 19+ */
input.error:-ms-input-placeholder { color: #C7254E; } /* ie */
input.error:-moz-placeholder { color: #C7254E; }

textarea.error::-webkit-input-placeholder { color: #C7254E; }
textarea.error::-moz-placeholder { color: #C7254E; } /* firefox 19+ */
textarea.error:-ms-input-placeholder { color: #C7254E; } /* ie */
textarea.error:-moz-placeholder { color: #C7254E; }


.multiple-agent-form .multiple-send-message {
    font-size: 14px;
    font-weight: 900;
    margin: 10px 0 0 !important;
    color: #0e90d9 !important;
    background: #fff !important;
    -moz-transition: .2s all;
    -webkit-transition: .2s all;
    -o-transition: .2s all;
    -ms-transition: .2s all;
    transition: .2s all;
}
.multiple-agent-form .multiple-send-message:hover {
    background: #435060 !important;
    color: #fff !important;
}

.multiple-copyright-area{color: #898989;}
.multiple-copyright-area *{
    font-family: "Roboto", Arial, Helvetica, sans-serif;
    font-size: 12px;
    font-weight: 400;
}
.multiple-copyright-area span{display: inline-block;line-height: 70px;}
.multiple-copyright-area a{color: #282828 !important;}


.erro-button a{margin-bottom: 15px;}
.single-query input, .single-query select{text-transform: none;}


.main .callus input[type=text],
.main .callus input[type=email]{
    padding: 6px 12px !important;
}

.cool_form .rb label{color: #000 !important;}
.cool_form .single-query{font-size: 13px;}
.cool_form .single-query input[type='radio']{display: inline-block; width: auto !important;}
.cool_form .single-query label{margin-top: 5px;}
.cool_form input[type='text'],
.cool_form input[type='email'],
.cool_form input[type='number']{
    height: 50px;
    line-height: 40px;
    padding: 0 30px 0 30px;
    font-size: 14px;
    margin-bottom: 0;
    color: #5d5d5d;
}
.cool_form textarea{
    padding: 20px 30px 10px 30px;
    resize: vertical;
    font-size: 1.08em; width: 100%; height: 169px;
    border-width: 1px !important;
}

/***----Colour Theme Control-----***/

.loader,
.erro-button a,
aside .group-button-search a.more-filter .fa,
.pagination ul li a,
.action_area2,
.action_area2 ul li a,
#image_div .property_hover,
#agent-2-slider .feature .tag-2, .tag_t,
#agent-2-slider .property_item .price .tag,
#image_div .rent_sale{background: #8d449a !important;color: #fff !important;}

.erro-button a:hover,
aside .group-button-search a.more-filter:hover .fa,
.pagination ul li a:hover, 
.pagination ul li.selected a,
.action_area2 ul li a:hover{background: #fff !important;color: #8d449a !important;}

.pagination ul li a{border-color: #8d449a;}
.pagination ul li a:hover, 
.pagination ul li.selected a{border-color: #8d449a;}

@media screen and (max-width: 1199px){ 
    nav.navbar.bootsnav ul.nav > li > a{padding: 0 10px;font-size: 12px !important;}
    nav.navbar.bootsnav ul.nav > li > a > span { display: block; padding: 0 14px; }
    .multiple-time-detail{padding-left: 0;}
    .col-lg-2b{width: 50%;}
    .row-eq-height {
        display: block;
    }
}



@media screen and (max-width: 768px){ 

    .modal-backdrop{display: none;}
    
    .table-row{display: block;}
    .table-row .item{display: inline-block;}

    .topbar ul.breadcrumb_top{ display: table; text-align: center; width: auto; margin: 0 auto; float: none; }
    nav.navbar.bootsnav ul.nav>li{padding: 0;}  

    .contact-page-1 { position: relative; min-height: 1490px; }
    .social_share_top {
        list-style: none;
        padding: 0;
        float: none;
        margin: 0 auto;
        display: table;
    }
    #featured-slider .item a.slider_text{left: 30px;width: 360px;}
    .multiple-copyright-area span {
        display: inline-block;
        line-height: 0px;
        padding: 20px 0;
    }
    .multiple-copyright-area .text-left{text-align: center;}
    .multiple-copyright-area .text-right{text-align: center;}   

    .social_block{padding: 15px;}
    .affiliates{padding: 20px 0;}

    #two-col-slider .userBlockHolder a{height: 170px;}
    #two-col-slider .userBlockHolder .property_details{font-size: 13px;}
}


@media screen and (max-width: 768px) and (orientation: landscape){ 
    .userBlockHolder a{height: 528px;}
    .property_list_results .userBlockHolder a{height: 458px;}
}

@media screen and (max-width: 480px){ 
    .row-eq-height { display: block; }

    .table-row{display: block;}
    .table-row .item{display: block;}

    #featured-slider .item a.slider_text{
        margin: 20px auto;
        width: 50%;
        left: 50px;  
    }

    nav.navbar.bootsnav .navbar-toggle{margin-bottom: 5px;}
    nav #navbar-menu{padding: 0 0 30px 0;}
    nav.navbar.bootsnav.no-full .navbar-collapse{max-height: 100%;}

    nav.navbar.bootsnav li.dropdown:not(hover) a.dropdown-toggle:before{content: '\f107';}
    nav.navbar.bootsnav ul.nav li.dropdown ul.dropdown-menu{background-color: #8d449a!important; }

    nav.navbar.bootsnav li.dropdown>a.dropdown-toggle:before{color: #282828;}

    nav.navbar.bootsnav li.dropdown.on > a.dropdown-toggle:before{color: #fff !important;}

    nav.navbar.bootsnav li.dropdown.on ul.dropdown-menu>li>a{color: #fff !important;border-bottom: solid 1px #90908f !important;}

    .topbar ul.breadcrumb_top{display: block;}
    .topbar ul.breadcrumb_top li{display: block;border: none;line-height: 25px;}
    .topbar ul.breadcrumb_top>li+li::before{content: '';}

    .property-query-area{padding: 20px;}

    .userBlockHolder a{height: 257px;}

    #deal h2{margin-bottom: 52px;}
    #four-item .owl-prev,
    #four-item .owl-next{
        top: -50px;
    }
    .topbar ul.breadcrumb_top{ display: table; text-align: center; width: auto; margin: 0 auto; float: none; }
    #usefulLinks .item .image{padding: 15px;}
    
    .col-lg-2b{width: 100%;}
    .agent_wrap .img-info{min-height: 120px;}

    .multiple-copyright-area .text-left{text-align: center;}
    .multiple-copyright-area .text-right{text-align: center;}
}
@media screen and (max-width: 400px){ 
    .userBlockHolder a{height: 245px;}   
}







